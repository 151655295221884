import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import {
  IPharmacistInfo,
  IWorkPlaceList,
  IWorkPlaceRequest,
  PharmacistInfo,
  WorkPlaceList,
  WorkPlaceRequest,
} from '../proto/models'

type InfoState = IPharmacistInfo & { userId: string }
export type PharmacistInfoState = {
  info?: InfoState
  workPlaces?: IWorkPlaceList
}

const initialState: PharmacistInfoState = {
  info: null,
  workPlaces: null,
}

const RESET_PHARMACIST_INFO_STATE = 'PHARMACIST_INFO_STATE'
export const resetState = createAction(RESET_PHARMACIST_INFO_STATE)

const SET_INFO = 'SET_PHARMACIST_INFO'
export const fetchPharmacistInfo = createAsyncThunk<InfoState, { userId: string }>(SET_INFO, async (options) => {
  const info = await ProtoClient.get<IPharmacistInfo>(
    'regional-manager-office/pharmacists/info',
    PharmacistInfo,
    options
  )

  return { ...info, userId: options.userId }
})

const SET_WORK_PLACES = 'PHARMACIST_INFO_SET_WORK_PLACES'
export const fetchWorkPlaces = createAsyncThunk<IWorkPlaceList, IWorkPlaceRequest>(SET_WORK_PLACES, async (options) => {
  return await ProtoClient.post<IWorkPlaceList>(
    'regional-manager-office/pharmacists/work-places',
    options,
    WorkPlaceRequest,
    WorkPlaceList
  )
})

const pharmacistInfoReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPharmacistInfo.fulfilled, (state, action) => {
      state.info = action.payload
    })
    .addCase(fetchWorkPlaces.fulfilled, (state, action) => {
      state.workPlaces = action.payload
    })
    .addCase(resetState, () => {
      return initialState
    })
})

export default pharmacistInfoReducer
