import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { IRegManagerOrganization, RegManagerOrganization } from '../proto/models'

export interface IRegionalManagerState {
  info?: IRegManagerOrganization
}

const initialState: IRegionalManagerState = {
  info: null,
}

const FETCH_ORGANIZATION = 'SET_REGIONAL_MANAGER_BRANCH_INFO'
export const fetchOrganization = createAsyncThunk<IRegManagerOrganization>(FETCH_ORGANIZATION, async () => {
  const url = 'regional-manager-office/organization'
  return await ProtoClient.get<IRegManagerOrganization>(url, RegManagerOrganization)
})

const regionalManagerReducer = createReducer(initialState, {
  [fetchOrganization.fulfilled.toString()]: (state, action) => {
    state.info = action.payload
  },
})

export default regionalManagerReducer
