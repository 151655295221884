import { connect } from 'react-redux'

import CourseProgressChart, {
  CourseProgressChartStateProps,
} from '../../../../../shared/components/EmployeeStatistics/Charts/CourseProgressChart'
import { IAppState } from '../../../../reducers/rootReducer'

const mapStateToProps = (store: IAppState): CourseProgressChartStateProps => {
  return {
    filter: store.employeeStatistics.courseProgressFilter,
  }
}

export default connect<CourseProgressChartStateProps, unknown, unknown, IAppState>(mapStateToProps)(CourseProgressChart)
