import { AuthError, ThrottleError } from 'external/rp.ui/utils/protoClient'
import { navigate, withPrefix } from 'gatsby'
import React from 'react'
import { Provider } from 'react-redux'
import { rootReducer } from 'regional-manager-office/reducers/rootReducer'
import { Error404, Error500 } from 'shared/components/Errors'
import PrivateRoute from 'shared/components/PrivateRoute/PrivateRoute'

import { Redirect, Router } from '@reach/router'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import PersonalProfilePage from '../PersonalProfile'
import EmployeeStatistics from '../Pharmacists/EmployeeStatistics'
import PharmacistInfoHeader from '../Pharmacists/PharmacistInfo/PharmacistInfoHeader'
import WorkPlacesTable from '../Pharmacists/PharmacistInfo/WorkPlaces/WorkPlacesTable'
import { PharmacistsPage } from '../Pharmacists/PharmacistsPage'
import AppWrapper from './AppWrapper'

const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false, thunk: true })

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV === 'development',
})

const App: React.FC = () => {
  React.useEffect(() => {
    window.onunhandledrejection = (ev: PromiseRejectionEvent) => {
      if (ev.reason instanceof ThrottleError) {
        return
      }

      if (ev.reason instanceof AuthError) {
        ev.preventDefault()
        navigate(`/`)
        return
      }
      return
    }

    return () => {
      window.onunhandledrejection = null
    }
  })

  return (
    <Provider store={store}>
      <Router>
        <Redirect
          from={withPrefix('/regional-manager-office/')}
          to={withPrefix('/regional-manager-office/pharmacists')}
          noThrow={true}
        />
        <PrivateRoute path={withPrefix('/regional-manager-office')} component={AppWrapper}>
          <Error500 path={withPrefix('/error')} />
          <Error404 default />
          <PharmacistsPage path={withPrefix('/pharmacists')} />
          <PharmacistInfoHeader path={withPrefix('/pharmacists/info/:userId')}>
            <WorkPlacesTable path="work" />
            <EmployeeStatistics path="statistics" />
          </PharmacistInfoHeader>
          <PersonalProfilePage path="/profile" />
        </PrivateRoute>
      </Router>
    </Provider>
  )
}

export default App
