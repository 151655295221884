import { DateTime } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'
import { info } from 'shared/components/CourseStatistics/CourseStatisticsInfo/BlockCharts.module.scss'
import Details, { IDetailsItemProps } from 'shared/components/CourseStatistics/Details'
import CubeQueryRender, {
  IChartComponentProps,
  ICubePivotConfig,
  renderChart,
} from 'shared/components/QueryRender/CubeQueryRender'
import { IPharmacistStatistics } from 'shared/proto/models'
import {
  getPharmacistStatistics,
  IStatisticsRequest,
} from 'shared/reducers/pharmacistStatisticsInfo/pharmacistStatisticsInfoReducer'

import { Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'

const requestUrl = 'regional-manager-office/pharmacists/stat'

const round = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100

const labelFormatter = (label: React.ReactText) => `${label}%`

const barRender = (props: BarRenderProps) => {
  let { resultSet, personalStatistics } = props
  resultSet = resultSet as { [key: string]: ResultSet }
  const pivotConfig: ICubePivotConfig = {
    x: [],
    y: [],
    fillMissingDates: true,
    joinDateRange: false,
  }

  const courses = resultSet[coursesCount].tablePivot(pivotConfig)[0][coursesCount]
  const tests = resultSet[testsAverageResult].tablePivot(pivotConfig)[0][testsAverageResult]

  const dataDetails: IDetailsItemProps[] = [
    {
      title: 'Просмотрено курсов:',
      value: courses.toString(),
    },
    {
      title: 'Средний результат:',
      value: labelFormatter(round(Number(tests))),
    },
    {
      title: 'Среднее время на тест:',
      value: personalStatistics?.testTime?.toString(),
    },
    {
      title: 'Сдано с 1:',
      value: personalStatistics?.coursesPassedWithFirstTry?.toString(),
    },
    {
      title: 'Сдано с 2:',
      value: personalStatistics?.coursesPassedWithSecondTry?.toString(),
    },
    {
      title: 'Сдано с 3:',
      value: personalStatistics?.coursesPassedWithThirdTry?.toString(),
    },
    {
      title: 'Не сдано:',
      value: personalStatistics?.notPassedCourses?.toString(),
    },
    {
      title: 'Не завершено:',
      value: personalStatistics?.notCompletedCourses?.toString(),
    },
  ]

  return (
    <div className={info}>
      <Details array={dataDetails} />
    </div>
  )
}

type BarRenderStateProps = {
  personalStatistics: IPharmacistStatistics
}

type BarRenderProps = BarRenderStateProps & IChartComponentProps

const mapBarRenderStateToProps = (store: IAppState): BarRenderStateProps => {
  return {
    personalStatistics: store.pharmacistStatistics.statistics,
  }
}

const connectedBarRender = connect<BarRenderStateProps, unknown, unknown, IAppState>(mapBarRenderStateToProps)(
  barRender
)

const chartRender = (props: QueryRendererRenderProps) => renderChart(connectedBarRender)(props)

const coursesCount = 'CourseWithOutFilters.count'
const testsAverageResult = 'CourseTestSession.averageResult'

const TotalsChart = (props: TotalsChartProps): React.ReactElement<TotalsChartProps> => {
  const { startDate, endDate, fetchPersonalStatistics, userId } = props

  React.useEffect(() => {
    fetchPersonalStatistics({
      userId,
      start: startDate.startOf('day').toISO({ includeOffset: false, suppressMilliseconds: true }),
      end: endDate.endOf('day').toISO({ includeOffset: false, suppressMilliseconds: true }),
    })
  }, [startDate, endDate])

  return (
    <CubeQueryRender
      queries={{
        [coursesCount]: {
          measures: [coursesCount],
          filters: props.totalFilters,
        },
        [testsAverageResult]: {
          measures: [testsAverageResult],
          filters: props.averageResultFilter,
        },
      }}
      render={chartRender}
    />
  )
}

export type TotalsChartStateProps = {
  assignCoursesFilters: Filter[]
  totalFilters: Filter[]
  averageResultFilter: Filter[]
  startDate: DateTime
  endDate: DateTime
}

export type TotalsChartDispatchProps = {
  fetchPersonalStatistics: (options: IStatisticsRequest) => void
}

type TotalsChartProps = TotalsChartStateProps & TotalsChartDispatchProps & { userId: string }

const mapStateToProps = (store: IAppState): TotalsChartStateProps => {
  return {
    totalFilters: store.employeeStatistics.totalFilters,
    averageResultFilter: store.employeeStatistics.averageResultFilter,
    assignCoursesFilters: [],
    startDate: store.employeeStatistics.startDate,
    endDate: store.employeeStatistics.endDate,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): TotalsChartDispatchProps => {
  return {
    fetchPersonalStatistics: (options: IStatisticsRequest) => {
      dispatch(getPharmacistStatistics(requestUrl, options))
    },
  }
}

export default connect<TotalsChartStateProps, TotalsChartDispatchProps, unknown, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(TotalsChart)
