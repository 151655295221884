import React, { ReactElement } from 'react'
import { RegionalManagerOffice } from 'regional-manager-office'

const Main = (): ReactElement => (
  <>
    <RegionalManagerOffice />
  </>
)

export default Main
