// extracted by mini-css-extract-plugin
export var forminfoSubtitle = "AdvancedFilterForm-module--forminfo-subtitle--34Jvg";
export var section = "AdvancedFilterForm-module--section--3zfSl";
export var sectionButtons = "AdvancedFilterForm-module--section-buttons--27_Qk";
export var sectionButton = "AdvancedFilterForm-module--section-button--1fQem";
export var forminfo = "AdvancedFilterForm-module--forminfo--2PT9R";
export var forminfoClose = "AdvancedFilterForm-module--forminfo-close--fLa6x";
export var forminfoCloseButton = "AdvancedFilterForm-module--forminfo-close-button--2uylv";
export var forminfoList = "AdvancedFilterForm-module--forminfo-list--3IY1n";
export var forminfoItem = "AdvancedFilterForm-module--forminfo-item--REjJJ";
export var forminfoItemWrapper = "AdvancedFilterForm-module--forminfo-item-wrapper--29XL_";
export var forminfoBlockSelect = "AdvancedFilterForm-module--forminfo-block-select--1EuSP";
export var forminfoBlockSearch = "AdvancedFilterForm-module--forminfo-block-search--2POzc";
export var forminfoTitle = "AdvancedFilterForm-module--forminfo-title--1S5U_";
export var forminfoSettings = "AdvancedFilterForm-module--forminfo-settings--LfOJx";
export var forminfoResult = "AdvancedFilterForm-module--forminfo-result--3uANd";