import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { AppDispatch, IAppState, resetAppState } from 'regional-manager-office/reducers/rootReducer'
import TechnicalNotification from 'shared/components/TechnicalNotification/TechnicalNotification'
import { IAlertForm } from 'shared/proto/models'
import { loadAlert } from 'shared/reducers/alert/alertReducer'

import Sidebar from '../Sidebar'
import { content, sidebar, wrapper } from './App.module.scss'

interface WrapperProps {
  path: string
  children: React.ReactNode
  onUnmount: () => void
  omMount: () => void
  alert: IAlertForm
}

const AppWrapper = (props: WrapperProps): ReactElement<WrapperProps> => {
  const { isShowMessage, message } = props.alert

  const getAdditionalStyle = (): string => {
    if (isShowMessage) {
      return message
    }

    return null
  }

  React.useEffect(() => {
    if (!isShowMessage) {
      props.omMount()
    }

    return () => {
      props.onUnmount
    }
  }, [])

  return (
    <>
      <TechnicalNotification isShow={isShowMessage} text={message} />
      <div className={classNames(wrapper, getAdditionalStyle())}>
        <div className={sidebar}>
          <Sidebar />
        </div>
        <main className={content}>{props.children}</main>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onUnmount: () => {
      dispatch(resetAppState())
    },
    omMount: () => {
      dispatch(loadAlert())
    },
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    alert: state.alert.alert,
  }
}

export default connect<{}, {}, WrapperProps>(mapStateToProps, mapDispatchToProps)(AppWrapper)
