import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import {
  Cell,
  Column,
  ColumnInterface,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useRowSelect,
  UseSortByState,
  useTable,
} from 'react-table'

import { DateHelper } from '../../../../../external/rp.ui/helpers/DateHelper'
/* eslint-disable react/display-name */
import { GuidHelper } from '../../../../../external/rp.ui/helpers/GuidHelper'
import Table from '../../../../../shared/components/Table'
import {
  tableCell,
  tableRow,
  tableText,
  tableTextContainer,
} from '../../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../../shared/components/TablePaging/TablePaging'
import { IWorkPlace, IWorkPlaceRequest } from '../../../../proto/models'
import { fetchWorkPlaces } from '../../../../reducers/pharmacistInfoReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import {
  section,
  sectionContent,
  tableCellAddress,
  tableCellEndDate,
  tableCellPharmacyNetwork,
  tableCellStartDate,
} from './WorkPlacesTable.module.scss'

const WorkPlacesTable = (props: Props): React.ReactElement<Props> => {
  const { fetchedData, totalRowCount } = props

  const getRowClass = () => tableRow

  const getCellClass = (column: ColumnInterface<IWorkPlace>) => {
    switch (column.id) {
      case 'pharmacyNetwork': {
        return classNames(tableCell, tableCellPharmacyNetwork)
      }
      case 'address': {
        return classNames(tableCell, tableCellAddress)
      }
      case 'startDate': {
        return classNames(tableCell, tableCellStartDate)
      }
      case 'endDate': {
        return classNames(tableCell, tableCellEndDate)
      }
    }
  }

  const columns: Column<IWorkPlace>[] = React.useMemo(
    () => [
      {
        Header: 'Аптечная сеть',
        id: 'pharmacyNetwork',
        accessor: 'pharmacyNetwork',
        Cell: (cell: Cell<IWorkPlace>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Адрес',
        id: 'address',
        accessor: 'address',
        Cell: (cell: Cell<IWorkPlace>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Дата начала',
        id: 'startDate',
        accessor: (row) => DateHelper.fromNumber(row.startDate as number).format('DD.MM.YYYY'),
        Cell: (cell: Cell<IWorkPlace>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
      {
        Header: 'Дата окончания',
        id: 'endDate',
        accessor: (row) => (row.endDate !== 0 ? DateHelper.fromNumber(row.endDate as number).format('DD.MM.YYYY') : ''),
        Cell: (cell: Cell<IWorkPlace>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
    ],
    []
  )

  const pageSizeOptions = [25, 50, 75]

  const [localPageSize, setLocalPageSize] = React.useState(pageSizeOptions[0])

  const data = React.useMemo(() => fetchedData, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IWorkPlace>> & Partial<UsePaginationState<IWorkPlace>> = {
    pageSize: localPageSize,
  }

  const tableOptions: TableOptions<IWorkPlace> & UsePaginationOptions<IWorkPlace> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualPagination: true,
  }

  const table = useTable<IWorkPlace>(tableOptions, usePagination, useRowSelect) as TableInstance<IWorkPlace> &
    UsePaginationInstanceProps<IWorkPlace>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize } = table.state as UsePaginationState<IWorkPlace> & UseSortByState<IWorkPlace>

  const onFetchDataDebounced = useAsyncDebounce(props.fetch, 2000)

  const userGuid = React.useMemo(() => GuidHelper.parse(props.userId), [props.userId])

  React.useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      userId: userGuid,
    })
  }, [pageIndex, pageSize, props.userId])

  return (
    <div className={section}>
      <div className={sectionContent}>
        <Table<IWorkPlace>
          tableInstance={table}
          getCellClass={getCellClass}
          getRowClass={getRowClass}
          addHover={true}
          rowWrap="nowrap"
          loading={props.loading}
        />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  )
}

type StateProps = {
  fetchedData: IWorkPlace[]
  totalRowCount: number
  loading: boolean
}

type OwnProps = {
  path: string
  userId?: string
}

type DispatchProps = {
  fetch: (options: IWorkPlaceRequest) => void
}

type Props = StateProps & OwnProps & DispatchProps

const mapStateToProps = (store: IAppState): StateProps => {
  const fetchedData = store.pharmacistInfo.workPlaces?.items ?? []
  const totalRowCount = store.pharmacistInfo.workPlaces?.rowCount
  const loading = store.pharmacistInfo.workPlaces === null

  return {
    fetchedData,
    totalRowCount,
    loading,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetch: (options: IWorkPlaceRequest) => {
      dispatch(fetchWorkPlaces(options))
    },
  }
}

export default connect<StateProps, DispatchProps, OwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(WorkPlacesTable)
