import AwesomeDebouncePromise from 'awesome-debounce-promise'
import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Link, withPrefix } from 'gatsby'
import { DateTime } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'
import {
  Cell,
  Column,
  ColumnInterface,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useRowSelect,
  UseSortByState,
  useTable,
} from 'react-table'
import {
  IAutocompleteSearchResult,
  ILocationSearchResult,
  IPharmacist,
  IPharmacistGetRequest,
  IPharmacistList,
  PharmacistList,
} from 'regional-manager-office/proto/models'
import PartialDateInput, { PartialDate } from 'shared/components/FlexibleDateInput/FlexibleDateInput'
import { TragetingUserSearchRequest } from 'shared/proto/models'

/* eslint-disable react/display-name */
import useAutocomplete from '@material-ui/lab/useAutocomplete'

import FilterButton from '../../../../shared/components/Buttons/FilterButton'
import CustomAutocompleteInput from '../../../../shared/components/CustomAutocompleteInput'
import PreviewIcon from '../../../../shared/components/Icons/PreviewIcon'
import Table from '../../../../shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableRow,
  tableText,
  tableTextContainer,
} from '../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../shared/components/TablePaging/TablePaging'
import {
  fetchPharmacistList,
  initPhrmacistList as initPharmacistList,
  setUserFromSearch,
  targetingInInitState,
  UserFromSearch,
} from '../../../reducers/pharmacistListReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import {
  customTable,
  filterDateInput,
  header,
  headerSearch,
  headerSearchButton,
  headerSearchInput,
  section,
  sectionContent,
  tableCell as customTableCell,
  tableCellButtons,
  tableCellLocation,
  tableCellName,
  tableCellNamePharm,
  tableCellNumber,
  tableSection,
} from './PharmacistsTable.module.scss'

const apiMethodUrl = 'regional-manager-office/pharmacists'

type PublicationTargetingOwnTableProps = {
  handleAdvancedFilterOpen?: () => void
  startDate: Date
  endDate: Date
}

type PublicationTargetingTableProps = PharmacistTableStateProps & PublicationTargetingOwnTableProps

interface UserAutocompleteProps {
  networkInclude: IAutocompleteSearchResult[]
  networkExclude: IAutocompleteSearchResult[]
  locationInclude: ILocationSearchResult[]
  locationExclude: ILocationSearchResult[]
  groupInclude: IAutocompleteSearchResult[]
  groupExclude: IAutocompleteSearchResult[]
  onChange: (user: UserFromSearch) => void
  userFromSearch: UserFromSearch
}

const onSearchValueChange = async (searchString: string, props: UserAutocompleteProps): Promise<IPharmacist[]> => {
  if (!searchString || searchString === '') {
    return []
  }

  const requestBody = {
    searchString: searchString,
    networkIncluded: props.networkInclude,
    networkExcluded: props.networkExclude,
    locationIncluded: props.locationInclude,
    locationExcluded: props.locationExclude,
    groupIncluded: props.groupInclude,
    groupExcluded: props.groupExclude,
  }

  const response = await ProtoClient.post<IPharmacistList>(
    apiMethodUrl + '/search',
    requestBody,
    TragetingUserSearchRequest,
    PharmacistList
  )

  return response?.items ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 2000)

const AutocompleteField = (props: UserAutocompleteProps) => {
  const [options, setOptions] = React.useState<IPharmacist[]>([])
  const [inputValue, setInputValue] = React.useState<string>(props.userFromSearch.name)

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    multiple: false,
    onInputChange: (event, value, reason) => {
      if (reason === 'input') {
        setInputValue(value)
        searchAPIDebounced(value, props).then((res) => {
          setOptions(res)
        })
      }
      if (reason === 'clear') {
        setOptions([])
        setInputValue(null)
        props.onChange({ id: null, name: null })
      }
    },
    options: options,
    filterOptions: (options) => options,
    getOptionLabel: (option) => option.fullName ?? '',
    onChange: (event, value, reason) => {
      if (reason === 'clear') {
        setInputValue(null)
        props.onChange({ id: null, name: null })
        return
      }
      if (!value) {
        return
      }
      const v = value as IPharmacist
      setInputValue(v.fullName)
      props.onChange({ id: v.id, name: v.fullName })
    },
    getOptionSelected: (option, value) => option.id === value.id,
    value: props.userFromSearch.name ?? '',
    inputValue: inputValue ?? '',
  })

  return (
    <CustomAutocompleteInput
      groupedOptions={groupedOptions}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      getOptionLabel={(option) => `${option.fullName} (${option.pharmacyName})`}
      placeholder="Введите запрос для поиска"
      type={'text'}
      icon="magnifier"
      size="normal"
      isReserveLabelError={false}
    />
  )
}

const PublicationTargetingTable = (
  props: PublicationTargetingTableProps
): React.ReactElement<PublicationTargetingTableProps> => {
  const {
    fetchedData,
    totalRowCount,
    userFromSearch,
    networkInclude,
    networkExclude,
    locationInclude,
    locationExclude,
    groupInclude,
    groupExclude,
    dispatch,
  } = props

  const getCellClass = (column: ColumnInterface<IPharmacist>) => {
    switch (column.id) {
      case 'fullName': {
        return classNames(tableCell, tableCellName, customTableCell)
      }
      case 'pharmacyName': {
        return classNames(tableCell, tableCellNamePharm, customTableCell)
      }
      case 'address': {
        return classNames(tableCell, tableCellLocation, customTableCell)
      }
      case 'rating': {
        return classNames(tableCell, tableCellNumber, customTableCell)
      }
      case 'passedCourseFirstTime': {
        return classNames(tableCell, tableCellNumber, customTableCell)
      }
      case 'passedCourseSecondTime': {
        return classNames(tableCell, tableCellNumber, customTableCell)
      }
      case 'passedCourseThirdTime': {
        return classNames(tableCell, tableCellNumber, customTableCell)
      }
      case 'buttons': {
        return classNames(tableCell, tableCellButtons, customTableCell)
      }
    }
  }

  const columns: Column<IPharmacist>[] = React.useMemo(
    () => [
      {
        Header: 'ФИО',
        id: 'fullName',
        accessor: 'fullName',
        Cell: (cell: Cell<IPharmacist>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Аптечная сеть',
        id: 'pharmacyName',
        accessor: 'pharmacyName',
        Cell: (cell: Cell<IPharmacist>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Адрес',
        id: 'address',
        accessor: 'address',
        Cell: (cell: Cell<IPharmacist>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
      {
        Header: 'Рейтинг',
        id: 'rating',
        accessor: 'rating',
        Cell: (cell: Cell<IPharmacist>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
      {
        id: 'buttons',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacist>) => (
          <div className={tableButtons}>
            <Link
              className={tableButtonsPreview}
              to={withPrefix(
                `/regional-manager-office/pharmacists/info/${GuidHelper.toString(cell.row.original.id)}/work`
              )}
            >
              <PreviewIcon />
            </Link>
          </div>
        ),
      },
    ],
    []
  )

  const pageSizeOptions = [25, 50, 75]

  const [localPageSize, setLocalPageSize] = React.useState(pageSizeOptions[0])

  const [date, setDate] = React.useState<PartialDate>({ year: DateTime.now().year, month: 0, day: 0 })

  const data = React.useMemo(() => fetchedData, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IPharmacist>> & Partial<UsePaginationState<IPharmacist>> = {
    pageSize: localPageSize,
  }

  const tableOptions: TableOptions<IPharmacist> & UsePaginationOptions<IPharmacist> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualPagination: true,
  }

  const table = useTable<IPharmacist>(tableOptions, usePagination, useRowSelect) as TableInstance<IPharmacist> &
    UsePaginationInstanceProps<IPharmacist>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize } = table.state as UsePaginationState<IPharmacist> & UseSortByState<IPharmacist>

  const userFromSearchId = React.useMemo(
    () => userFromSearch?.id && GuidHelper.toString(userFromSearch.id),
    [userFromSearch.id]
  )

  const loadData = (options: IPharmacistGetRequest) => {
    props.dispatch(fetchPharmacistList(options))
  }

  const onFetchDataDebounced = useAsyncDebounce(loadData, 2000)

  const fetchData = (props: PublicationTargetingTableProps) => {
    if (targetingInInitState({ ...props })) {
      dispatch(
        initPharmacistList({
          pageIndex,
          pageSize,
          userId: userFromSearchId,
          endpoint: 'regional-manager-office/pharmacists/list',
          year: date.year,
          month: date.month,
          day: date.day,
        })
      )
    } else {
      onFetchDataDebounced({
        pageSize,
        pageIndex,
        networkIncluded: networkInclude,
        networkExcluded: networkExclude,
        locationIncluded: locationInclude,
        locationExcluded: locationExclude,
        userId: userFromSearch.id,
        groupIncluded: groupInclude,
        groupExcluded: groupExclude,
        year: date.year,
        month: date.month,
        day: date.day,
      })
    }
  }

  React.useEffect(() => {
    fetchData(props)
  }, [
    pageIndex,
    pageSize,
    networkInclude,
    networkExclude,
    locationInclude,
    locationExclude,
    userFromSearch,
    groupInclude,
    groupExclude,
    date,
  ])

  return (
    <div className={section}>
      <div className={sectionContent}>
        <div className={header}>
          <div className={headerSearch}>
            <div className={headerSearchInput}>
              <AutocompleteField
                onChange={(user) => {
                  props.dispatch(setUserFromSearch(user))
                  gotoPage(0)
                }}
                networkInclude={props.networkInclude}
                networkExclude={props.networkExclude}
                locationInclude={props.locationInclude}
                locationExclude={props.locationExclude}
                groupInclude={props.groupInclude}
                groupExclude={props.groupExclude}
                userFromSearch={props.userFromSearch}
              />
            </div>
            <div className={headerSearchButton}>
              <FilterButton onClick={props.handleAdvancedFilterOpen} />
            </div>
            <div className={filterDateInput}>
              <PartialDateInput defaultDate={date} onFilterButtonClick={setDate} tooltipButton="Применить фильтр" />
            </div>
          </div>
        </div>
        <div className={tableSection}>
          <Table<IPharmacist>
            tableInstance={table}
            getCellClass={getCellClass}
            getRowClass={() => tableRow}
            addHover={true}
            rowWrap="nowrap"
            loading={props.loading}
            additionalTableStyles={customTable}
          />
        </div>
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  )
}

type PharmacistTableStateProps = {
  networkInclude: IAutocompleteSearchResult[]
  networkExclude: IAutocompleteSearchResult[]
  groupExclude: IAutocompleteSearchResult[]
  groupInclude: IAutocompleteSearchResult[]
  locationInclude: ILocationSearchResult[]
  locationExclude: ILocationSearchResult[]
  loading: boolean
  fetchedData: IPharmacist[]
  totalRowCount: number
  dispatch?: AppDispatch
  userFromSearch: UserFromSearch
}

const mapStateToProps = (store: IAppState): PharmacistTableStateProps => {
  const networkInclude = store.pharmacists.networkInclude ?? []
  const networkExclude = store.pharmacists.networkExclude ?? []
  const locationInclude = store.pharmacists.locationInclude ?? []
  const locationExclude = store.pharmacists.locationExclude ?? []
  const groupInclude = store.pharmacists.groupInclude ?? []
  const groupExclude = store.pharmacists.groupExclude ?? []
  const loading = store.pharmacists.loading
  const fetchedData = store.pharmacists.fetchedData
  const totalRowCount = store.pharmacists.totalRowCount
  const dispatch = store.dispatch
  const userFromSearch = store.pharmacists.userFromSearch

  return {
    networkInclude,
    networkExclude,
    locationInclude,
    locationExclude,
    loading,
    fetchedData,
    totalRowCount,
    groupInclude,
    dispatch,
    groupExclude,
    userFromSearch,
  }
}

export default connect<PharmacistTableStateProps, unknown, PublicationTargetingOwnTableProps, IAppState>(
  mapStateToProps
)(PublicationTargetingTable)
