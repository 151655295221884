import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'

import UserInfo from 'shared/components/UserInfo'
import { IImage } from 'shared/proto/models'
import { tryToLeave } from 'shared/reducers/application/applicationReducer'
import { getProfileInfo } from 'shared/reducers/personalProfile/personalProfileReducer'
import { logout } from 'shared/reducers/user/userReducer'

import { AppDispatch, IAppState } from '../../reducers/rootReducer'

interface IUserInfoProps {
  dispatch: AppDispatch
  name: string
  image?: IImage
  currentFormDirty: boolean
}

const UserInfoBlock = (props: IUserInfoProps): ReactElement<IUserInfoProps> => {
  const onLogoutClick = () => (props.currentFormDirty ? props.dispatch(tryToLeave(() => logout())) : logout())

  useEffect(() => {
    props.dispatch(getProfileInfo())
  }, [])

  return <UserInfo name={props.name} onLogoutClick={onLogoutClick} image={props.image} />
}

const mapStateToProps = (state: IAppState): IUserInfoProps => {
  const fullName = `${state.profile.profileInfo.lastName ?? ''} ${state.profile.profileInfo.firstName ?? ''}`

  return {
    dispatch: state.dispatch,
    name: fullName,
    image: state.profile.profileInfo.image,
    currentFormDirty: state.application.currentFormDirty,
  }
}

export default connect(mapStateToProps)(UserInfoBlock)
