import { connect } from 'react-redux'

import TestingStatisticsChart, {
  TestingStatisticsChartStateProps,
} from '../../../../../shared/components/EmployeeStatistics/Charts/TestingStatisticsChart'
import { IAppState } from '../../../../reducers/rootReducer'

const mapStateToProps = (store: IAppState): TestingStatisticsChartStateProps => {
  return {
    successfullAttemptsFilters: store.employeeStatistics.successfullAttemptsFilters,
    failedAttemptsFilter: store.employeeStatistics.failedAttemptsFilter,
    timedOutAttemptsFilter: store.employeeStatistics.timedOutAttemptsFilter,
  }
}

export default connect<TestingStatisticsChartStateProps, unknown, unknown, IAppState>(mapStateToProps)(
  TestingStatisticsChart
)
