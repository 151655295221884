import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { ProtoClient } from 'external/rp.ui/utils/protoClient'

import {
  IAutocompleteSearchResult,
  ILocationSearchResult,
  IPharmacist,
  IPharmacistGetRequest,
  IPharmacistList,
  PharmacistGetRequest,
  PharmacistList,
} from 'regional-manager-office/proto/models'
import { AppDispatch } from './rootReducer'

const url = 'regional-manager-office/pharmacists'

export type UserFromSearch = {
  id: Uint8Array
  name: string
}
export interface IPharmacistListState {
  networkInclude: IAutocompleteSearchResult[]
  networkExclude: IAutocompleteSearchResult[]
  groupExclude: IAutocompleteSearchResult[]
  groupInclude: IAutocompleteSearchResult[]
  locationInclude: ILocationSearchResult[]
  locationExclude: ILocationSearchResult[]
  loading: boolean
  fetchedData: IPharmacist[]
  totalRowCount: number
  userFromSearch: UserFromSearch
  showWarning: boolean
  dispatch?: AppDispatch
  submitting?: boolean
  courseId?: string
}

const initialState: IPharmacistListState = {
  locationExclude: [],
  locationInclude: [],
  networkExclude: [],
  networkInclude: [],
  groupExclude: [],
  groupInclude: [],
  loading: true,
  fetchedData: [],
  totalRowCount: 0,
  userFromSearch: {
    id: null,
    name: null,
  },
  showWarning: false,
  submitting: false,
}

export interface IPharmacistListRequest {
  pageSize: number
  pageIndex: number
  userId: string
  endpoint?: string
  year: number
  month: number
  day: number
}

const RESET_STATE = 'PHARMACIST_LIST_RESET_STATE'
export const resetState = createAction(RESET_STATE)

type SetFilterParams = {
  networkInclude?: IAutocompleteSearchResult[]
  networkExclude?: IAutocompleteSearchResult[]
  groupExclude?: IAutocompleteSearchResult[]
  groupInclude?: IAutocompleteSearchResult[]
  locationInclude: ILocationSearchResult[]
  locationExclude: ILocationSearchResult[]
}
const SET_FILTER = 'PHARMACIST_LIST_SET_FILTER'
export const setTargetingFilter = createAction<SetFilterParams>(SET_FILTER)

const FETCH = 'PHARMACIST_LIST_FETCH'
type ReturnType = {
  fetchedData: IPharmacist[]
  totalRowCount: number
}

export const targetingInInitState = (state: SetFilterParams) => {
  return (
    state.networkInclude.length === 0 &&
    state.networkExclude.length === 0 &&
    state.groupExclude.length === 0 &&
    state.groupInclude.length === 0 &&
    state.locationInclude.length === 0 &&
    state.locationExclude.length === 0
  )
}

export const fetchPharmacistList = createAsyncThunk<ReturnType, IPharmacistGetRequest>(FETCH, async (options) => {
  const response = await ProtoClient.post<IPharmacistList>(url + '/list', options, PharmacistGetRequest, PharmacistList)

  const result = {
    fetchedData: response?.items ?? [],
    totalRowCount: response?.rowCount ?? 0,
  }

  return result
})

const INIT = 'PHARMACIST_LIST_INIT'
export const initPhrmacistList = createAsyncThunk<ReturnType, IPharmacistListRequest>(INIT, async (options) => {
  const paging: IPharmacistListRequest = {
    pageSize: options.pageSize,
    pageIndex: options.pageIndex,
    userId: null,
    year: options.year,
    month: options.month,
    day: options.day,
  }

  if (options.userId && options.userId.length > 0) {
    paging.userId = options.userId
  }

  const response = await ProtoClient.get<IPharmacistList>(url + '/list', PharmacistList, paging)

  const result = {
    fetchedData: response?.items ?? [],
    totalRowCount: response?.rowCount ?? 0,
  }

  return result
})

const SET_USER_FROM_SEARCH = 'PHARMACIST_LIST_SET_USER_FROM_SEARCH'
export const setUserFromSearch = createAction<UserFromSearch>(SET_USER_FROM_SEARCH)

const RESET_FILTER = 'PHARMACIST_LIST_RESET_FILTER'
export const resetFilter = createAction(RESET_FILTER)

const pharmacistListReducer = createReducer(initialState, {
  [RESET_STATE]: () => initialState,
  [SET_FILTER]: (state, action: { payload: SetFilterParams }) => {
    state.networkInclude = action.payload.networkInclude ?? []
    state.networkExclude = action.payload.networkExclude ?? []
    state.groupInclude = action.payload.groupInclude ?? []
    state.groupExclude = action.payload.groupExclude ?? []
    state.locationInclude = action.payload.locationInclude ?? []
    state.locationExclude = action.payload.locationExclude ?? []
  },
  [fetchPharmacistList.pending.toString()]: (state) => {
    state.loading = true
  },
  [fetchPharmacistList.rejected.toString()]: (state) => {
    state.loading = false
  },
  [fetchPharmacistList.fulfilled.toString()]: (state, action: { payload: ReturnType }) => {
    state.fetchedData = action.payload.fetchedData
    state.totalRowCount = action.payload.totalRowCount
    state.loading = false
  },
  [initPhrmacistList.fulfilled.toString()]: (state, action: { payload: ReturnType }) => {
    state.fetchedData = action.payload.fetchedData
    state.totalRowCount = action.payload.totalRowCount
    state.loading = false
  },
  [initPhrmacistList.pending.toString()]: (state) => {
    state.loading = true
  },
  [initPhrmacistList.rejected.toString()]: (state) => {
    state.loading = false
  },
  [SET_USER_FROM_SEARCH]: (state, action) => {
    state.userFromSearch = action.payload
  },
  [RESET_FILTER]: (state) => {
    state.networkInclude = []
    state.networkExclude = []
    state.groupInclude = []
    state.groupExclude = []
    state.locationInclude = []
    state.locationExclude = []
  },
})

export default pharmacistListReducer
