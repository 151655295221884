import { withPrefix } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'

import ProvisorExperience from '../../../shared/components/ProvisorExperience'
import SafeLink from '../../../shared/components/SafeLink'
import Header from '../../../shared/components/Sidebar/Header'
import Navigation, { IDataNavigation } from '../../../shared/components/Sidebar/Navigation'
import { IExperienceDetail } from '../../../shared/proto/models'
import { AppDispatch } from '../../../shared/reducers/CourseDesigner'
import { fetchMenuItems } from '../../../shared/reducers/menuItems/menuItemsReducer'
import { getProvisorExperience } from '../../../shared/reducers/provisorExperience/provisorExperienceReducer'
import { IAppState } from '../../reducers/rootReducer'
import UserInfo from '../UserInfo/UserInfo'
import { sidebar } from './Sidebar.module.scss'

const dataNavigation: (IDataNavigation<unknown> & { key: string })[] = [
  {
    id: 1,
    name: 'Провизоры',
    link: withPrefix('regional-manager-office/pharmacists'),
    class: 'pharmacists',
    key: 'pharmacists',
  },
  {
    id: 5,
    name: 'Личный профиль',
    link: withPrefix('regional-manager-office/profile'),
    class: 'profile',
    key: 'profile',
  },
]

interface ISidebarContext {
  dispatch: AppDispatch
  loading: boolean
  menuItems: string[]
  totalProvisors: number
  provisors: IExperienceDetail[]
  provisorsLoading: boolean
}

class Sidebar extends React.Component<ISidebarContext> {
  componentDidMount() {
    this.props.dispatch(fetchMenuItems('regional-manager-office/menu/get-menu-items'))
    this.props.dispatch(getProvisorExperience())
  }

  private getMenuItems = () => {
    const menuItems = this.props.menuItems
    if (menuItems.length !== 0) {
      return dataNavigation.filter((dn) => menuItems.includes(dn.key))
    }

    return []
  }

  render() {
    return (
      <aside className={sidebar}>
        <Header />
        <UserInfo />
        <Navigation dataNavigation={this.getMenuItems()} linkElement={SafeLink} />
        {!this.props.provisorsLoading && (
          <ProvisorExperience data={this.props.provisors} total={this.props.totalProvisors} />
        )}
      </aside>
    )
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loading: store.menuItems.loading,
    menuItems: store.menuItems.menuItems,
    provisors: store.provisorExperience.provisors,
    totalProvisors: store.provisorExperience.total,
    provisorsLoading: store.provisorExperience.loading,
  }
}

export default connect(mapStateToProps, null)(Sidebar)
