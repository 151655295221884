import { withPrefix } from 'gatsby'
import React, { useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import Breadcrumbs, { IBreadcrumbs } from '../../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import NavigationTab from '../../../../shared/components/NavigationTab/NavigationTab'
import PageInfoHeader from '../../../../shared/components/PageInfoHeader/PageInfoHeader'
import PageTitle from '../../../../shared/components/PageTitle'
import { IPharmacistInfo } from '../../../proto/models'
import { fetchPharmacistInfo, resetState } from '../../../reducers/pharmacistInfoReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

const getDataNavigation = (props: OwnProps) => {
  return [
    {
      id: nextId(),
      name: 'Места работы',
      link: withPrefix(`/regional-manager-office/pharmacists/info/${props.userId}/work`),
    },
    {
      id: nextId(),
      name: 'Статистика',
      link: withPrefix(`/regional-manager-office/pharmacists/info/${props.userId}/statistics`),
    },
  ]
}

const getDataPageInfoHeader = (employee: IPharmacistInfo) => {
  return [
    {
      id: nextId(),
      title: 'ФИО',
      value: employee.fullName,
    },
    {
      id: nextId(),
      title: 'Опыт работы',
      value: employee.experience,
    },
    {
      id: nextId(),
      title: 'Адрес текущего места работы',
      value: employee.pharmacy,
    },
    {
      id: nextId(),
      title: 'Аптечная сеть',
      value: employee.pharmacyNetwork,
    },
  ]
}

const getDataBreadcrumbs = (): IBreadcrumbs[] => {
  return [
    {
      title: 'Провизоры',
      link: withPrefix(`/regional-manager-office/pharmacists`),
    },
    {
      title: 'Информация о провизоре',
      currSection: true,
    },
  ]
}

const PharmacistInfoHeader = (props: Props): React.ReactElement<Props> => {
  useEffect(() => {
    props.fetch(props.userId)
    return props.reset
  }, [props.userId])

  return (
    <>
      <Breadcrumbs list={getDataBreadcrumbs()} />
      <PageTitle title="Информация о провизоре" />
      {!props.info ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageInfoHeader data={getDataPageInfoHeader(props.info)} />
          <NavigationTab data={getDataNavigation(props)} />
          {props.children}
        </>
      )}
    </>
  )
}

type StateProps = {
  info: IPharmacistInfo
}

type OwnProps = {
  path: string
  children: React.ReactNode
  userId?: string
}

type DispatchProps = {
  fetch: (userId: string) => void
  reset: () => void
}

type Props = StateProps & OwnProps & DispatchProps

const mapStateToProps = (store: IAppState): StateProps => {
  return {
    info: store.pharmacistInfo.info,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetch: (userId: string) => {
      dispatch(fetchPharmacistInfo({ userId }))
    },
    reset: () => {
      dispatch(resetState())
    },
  }
}

export default connect<StateProps, DispatchProps, OwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(PharmacistInfoHeader)
