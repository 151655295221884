// extracted by mini-css-extract-plugin
export var section = "PharmacistsTable-module--section--LNrAC";
export var sectionContent = "PharmacistsTable-module--section-content--2PzXO";
export var customTable = "PharmacistsTable-module--customTable--2gFFq";
export var header = "PharmacistsTable-module--header--TMAal";
export var headerSearch = "PharmacistsTable-module--header-search--3ZThs";
export var headerSearchButton = "PharmacistsTable-module--header-search-button--wlD5g";
export var headerSearchInput = "PharmacistsTable-module--header-search-input--39Iig";
export var tableSection = "PharmacistsTable-module--tableSection--1SbAB";
export var filterDateInput = "PharmacistsTable-module--filter-date-input--bHoLw";
export var tableCell = "PharmacistsTable-module--table-cell--3Oigz";
export var tableCellName = "PharmacistsTable-module--table-cell-name--RjXA8";
export var tableCellNamePharm = "PharmacistsTable-module--table-cell-name-pharm--39Ms7";
export var tableCellLocation = "PharmacistsTable-module--table-cell-location--WocxE";
export var tableCellNumber = "PharmacistsTable-module--table-cell-number--1nlq_";
export var tableCellButtons = "PharmacistsTable-module--table-cell-buttons--8Ai6k";