import React from 'react'

import AdvancedFilterForm from './AdvancedFilter/AdvancedFilterForm'
import Header from './Header'
import Pharmacists from './Pharmacists'

type PharmacistsPageProps = {
  path?: string
}

export const PharmacistsPage = (props: PharmacistsPageProps): React.ReactElement<PharmacistsPageProps> => (
  <>
    <Header />
    <Pharmacists AdvancedFilterForm={AdvancedFilterForm} />
  </>
)
