import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import React, { useState } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'
import { arrayPush, destroy, FieldArray, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'

import useAutocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete'

import TextButton from '../../../../shared/components/Buttons/TextButton'
import TextCloseButton from '../../../../shared/components/Buttons/TextCloseButton'
import { TargetingFilterFormProps } from '../../../../shared/components/CourseDesigner/Publications/CreatePublication/CreatePublication'
import CustomAutocompleteInput from '../../../../shared/components/CustomAutocompleteInput'
import CustomSelect, { IOptions } from '../../../../shared/components/CustomSelect/CustomSelect'
import IncludeExcludeMembers from '../../../../shared/components/IncludeExcludeMembers'
import {
  AutocompleteSearchResults,
  IAutocompleteSearchResult,
  IAutocompleteSearchResults,
  ILocationSearchResult,
  ILocationSearchResults,
  LocationSearchResults,
} from '../../../../shared/proto/models'
import { IPharmacistListState, resetFilter, setTargetingFilter } from '../../../reducers/pharmacistListReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import {
  forminfo,
  forminfoBlockSearch,
  forminfoBlockSelect,
  forminfoClose,
  forminfoCloseButton,
  forminfoItem,
  forminfoItemWrapper,
  forminfoList,
  forminfoResult,
  forminfoSettings,
  forminfoSubtitle,
  forminfoTitle,
  section,
  sectionButton,
  sectionButtons,
} from './AdvancedFilterForm.module.scss'

const url = 'publication-targeting'

enum EConnectionType {
  Include = 10,
  Exclude = 20,
}

const customSelectData: IOptions[] = [
  {
    id: nextId(),
    text: 'Включить',
    value: EConnectionType.Include as number,
  },
  {
    id: nextId(),
    text: 'Исключить',
    value: EConnectionType.Exclude as number,
  },
]

interface FilterProps {
  placeholder: string
  dispatch: AppDispatch
  fieldNamePrefix: string
  existingRecords: IAutocompleteSearchResult[]
}

const networkSearch = (searchString: string) =>
  searchString &&
  ProtoClient.get<IAutocompleteSearchResults>(url + '/search-networks', AutocompleteSearchResults, {
    searchString,
  })

const debouncedNetworkSearch = AwesomeDebouncePromise(networkSearch, 500)

function Filter(props: FilterProps) {
  const [searchText, setSearchText] = useState('')
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [fetchedData, setFetchedData] = useState<IAutocompleteSearchResult[]>([])
  const [connectionType, setConnectionType] = useState<EConnectionType>(EConnectionType.Include)

  const onInputChange = async (
    event: React.ChangeEvent<unknown>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      setFetchedData([])
      setSearchText(value)

      const data = await debouncedNetworkSearch(value)
      if (data && data.SearchResults) {
        setFetchedData(data.SearchResults)
      } else {
        setFetchedData([])
      }
    }
    if (reason === 'clear') {
      setAutocompleteOpen(false)
    }
  }

  const checkNotDuplicate = (newRecord: IAutocompleteSearchResult) => {
    const findDuplicateCourseAudience = (current: IAutocompleteSearchResult) =>
      GuidHelper.equals(current.id, newRecord.id)

    return !props.existingRecords.find(findDuplicateCourseAudience)
  }

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    multiple: false,
    inputValue: searchText,
    open: autocompleteOpen,
    onOpen: () => setAutocompleteOpen(true),
    onClose: () => setAutocompleteOpen(false),
    onInputChange: onInputChange,
    getOptionSelected: (option, value) => GuidHelper.equals(option.id, value.id),
    getOptionLabel: (option) => option.fullName ?? '',
    filterOptions: (options) => options,
    options: fetchedData as IAutocompleteSearchResult[],
    onChange: (event, value) => {
      const info = value as IAutocompleteSearchResult
      if (value) {
        const arrayName =
          (connectionType as EConnectionType) === EConnectionType.Include
            ? `${props.fieldNamePrefix}Include`
            : `${props.fieldNamePrefix}Exclude`
        if (checkNotDuplicate(info)) {
          props.dispatch(arrayPush('advancedTargetingForm', arrayName, info))
        }
        setAutocompleteOpen(false)
        setFetchedData([])
        setSearchText('')
      }
    },
  })

  const getValue = (value: IAutocompleteSearchResult): string => value.fullName

  return (
    <>
      <div className={forminfoSettings}>
        <div className={forminfoBlockSelect}>
          <CustomSelect
            options={customSelectData}
            onChange={(value) => {
              setConnectionType(+value)
            }}
            size="large"
          />
        </div>
        <div className={forminfoBlockSearch}>
          <CustomAutocompleteInput
            placeholder={props.placeholder}
            type="text"
            getOptionProps={getOptionProps}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            groupedOptions={groupedOptions}
            getListboxProps={getListboxProps}
            getOptionLabel={getValue}
            icon="magnifier"
            isReserveLabelError={false}
          />
        </div>
      </div>
      <div className={forminfoResult}>
        <FieldArray
          name={`${props.fieldNamePrefix}Include`}
          component={IncludeExcludeMembers}
          title={'Включить'}
          getValue={getValue}
        />
        <FieldArray
          name={`${props.fieldNamePrefix}Exclude`}
          component={IncludeExcludeMembers}
          title={'Исключить'}
          getValue={getValue}
        />
      </div>
    </>
  )
}

interface LocationFilterProps {
  placeholder: string
  dispatch: AppDispatch
  fieldNamePrefix: string
  existingRecords: ILocationSearchResult[]
}

const locationSearch = (searchString: string) =>
  searchString &&
  ProtoClient.get<ILocationSearchResults>(url + '/search-location', LocationSearchResults, {
    searchString,
  })

const debouncedLocationSearch = AwesomeDebouncePromise(locationSearch, 500)

function LocationFilter(props: LocationFilterProps) {
  const [searchText, setSearchText] = useState('')
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [fetchedData, setFetchedData] = useState<ILocationSearchResult[]>([])
  const [connectionType, setConnectionType] = useState<EConnectionType>(EConnectionType.Include)

  const onInputChange = async (
    event: React.ChangeEvent<unknown>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      setFetchedData([])
      setSearchText(value)

      const data = await debouncedLocationSearch(value)
      if (data && data.items) {
        setFetchedData(data.items)
      } else {
        setFetchedData([])
      }
    }
    if (reason === 'clear') {
      setAutocompleteOpen(false)
    }
  }

  const checkNotDuplicate = (newRecord: ILocationSearchResult) => {
    const findDuplicateCourseAudience = (current: ILocationSearchResult) => {
      return (
        GuidHelper.equals(current.countryId, newRecord.countryId) &&
        GuidHelper.equals(current.regionId, newRecord.regionId) &&
        GuidHelper.equals(current.settlementId, newRecord.settlementId) &&
        GuidHelper.equals(current.districtId, newRecord.districtId) &&
        GuidHelper.equals(current.streetId, newRecord.streetId) &&
        GuidHelper.equals(current.houseId, newRecord.houseId)
      )
    }

    return !props.existingRecords.find(findDuplicateCourseAudience)
  }

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    multiple: false,
    inputValue: searchText,
    open: autocompleteOpen,
    onOpen: () => setAutocompleteOpen(true),
    onClose: () => setAutocompleteOpen(false),
    onInputChange: onInputChange,
    getOptionSelected: (option, value) => {
      return (
        GuidHelper.equals(option.countryId, value.countryId) &&
        GuidHelper.equals(option.regionId, value.regionId) &&
        GuidHelper.equals(option.settlementId, value.settlementId) &&
        GuidHelper.equals(option.districtId, value.districtId) &&
        GuidHelper.equals(option.streetId, value.streetId) &&
        GuidHelper.equals(option.houseId, value.houseId)
      )
    },
    getOptionLabel: (option) =>
      [
        option.countryName,
        option.regionName,
        option.settlementName,
        option.districtName,
        option.streetName,
        option.houseNumber,
      ]
        .filter((v) => v !== '')
        .join(', '),
    filterOptions: (options) => options,
    options: fetchedData as ILocationSearchResult[],
    onChange: (event, value) => {
      const info = value as ILocationSearchResult
      if (value) {
        const arrayName =
          (connectionType as EConnectionType) === EConnectionType.Include
            ? `${props.fieldNamePrefix}Include`
            : `${props.fieldNamePrefix}Exclude`
        if (checkNotDuplicate(info)) {
          props.dispatch(arrayPush('advancedTargetingForm', arrayName, info))
        }
        setAutocompleteOpen(false)
        setFetchedData([])
        setSearchText('')
      }
    },
  })

  const getValue = (value: ILocationSearchResult): string => {
    return [
      value.countryName,
      value.regionName,
      value.settlementName,
      value.districtName,
      value.streetName,
      value.houseNumber,
    ]
      .filter((v) => v !== '')
      .join(', ')
  }

  return (
    <>
      <div className={forminfoSettings}>
        <div className={forminfoBlockSelect}>
          <CustomSelect
            options={customSelectData}
            onChange={(value) => {
              setConnectionType(+value)
            }}
            size="large"
          />
        </div>
        <div className={forminfoBlockSearch}>
          <CustomAutocompleteInput
            placeholder={props.placeholder}
            type="text"
            getOptionProps={getOptionProps}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            groupedOptions={groupedOptions}
            getListboxProps={getListboxProps}
            getOptionLabel={getValue}
            icon="magnifier"
            isReserveLabelError={false}
          />
        </div>
      </div>
      <div className={forminfoResult}>
        <FieldArray
          name={`${props.fieldNamePrefix}Include`}
          component={IncludeExcludeMembers}
          title={'Включить'}
          getValue={getValue}
        />
        <FieldArray
          name={`${props.fieldNamePrefix}Exclude`}
          component={IncludeExcludeMembers}
          title={'Исключить'}
          getValue={getValue}
        />
      </div>
    </>
  )
}

interface AdvancedTargetingReduxFormProps {
  networkInclude: IAutocompleteSearchResult[]
  networkExclude: IAutocompleteSearchResult[]
  locationInclude: ILocationSearchResult[]
  locationExclude: ILocationSearchResult[]
}

type AdvancedTargetingFormProps = TargetingFilterFormProps & StateProps & DispatchProps

type FormProps = InjectedFormProps<AdvancedTargetingReduxFormProps, AdvancedTargetingFormProps, string> &
  AdvancedTargetingFormProps

const AdvancedTargetingForm = (props: FormProps) => {
  const { handleSubmit, submitting } = props

  return (
    <div className={section}>
      <form onSubmit={handleSubmit} className={forminfo}>
        <div className={forminfoClose}>
          <div className={forminfoCloseButton}>
            <TextCloseButton iconPosition="right" onClick={props.closeForm} />
          </div>
        </div>
        <ul className={forminfoList}>
          <li className={forminfoItem}>
            <div className={forminfoItemWrapper}>
              <h2 className={forminfoTitle}>Настройка отображения по аптечным сетям</h2>
              <label className={forminfoSubtitle}>Аптечная сеть</label>
              <Filter
                {...props}
                placeholder={'Введите аптечную сеть'}
                fieldNamePrefix={'network'}
                existingRecords={props.formNetworkVisibility}
              />
            </div>
          </li>
          <li className={forminfoItem}>
            <div className={forminfoItemWrapper}>
              <h2 className={forminfoTitle}>Настройка по местоположению</h2>
              <label className={forminfoSubtitle}>Местоположение</label>
              <LocationFilter
                {...props}
                placeholder={'Выберите геолокацию'}
                fieldNamePrefix={'location'}
                existingRecords={props.formLocationVisibility}
              />
            </div>
          </li>
        </ul>
        <div className={sectionButtons}>
          <div className={sectionButton}>
            <TextButton text="Применить фильтр" type="submit" disabled={submitting} />
          </div>
          <div className={sectionButton}>
            <TextButton
              text="Сбросить фильтр"
              type="button"
              disabled={submitting}
              color="white"
              onClick={() => props.resetForm()}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

const AdvancedTargetingReduxForm = reduxForm<AdvancedTargetingReduxFormProps, AdvancedTargetingFormProps, string>({
  form: 'advancedTargetingForm',
})(AdvancedTargetingForm)

const selector = formValueSelector('advancedTargetingForm')

const mapStateToProps = (store: IAppState) => {
  const networkInclude = selector(store, 'networkInclude') ?? []
  const networkExclude = selector(store, 'networkExclude') ?? []
  const locationInclude = selector(store, 'locationInclude') ?? []
  const locationExclude = selector(store, 'locationExclude') ?? []

  const formNetworkVisibility = networkInclude?.concat(networkExclude) || networkExclude?.concat(networkInclude)
  const formLocationVisibility = locationInclude?.concat(locationExclude) || locationExclude?.concat(locationInclude)

  const initialValues = store.pharmacists

  return {
    formNetworkVisibility,
    formLocationVisibility,
    initialValues,
  }
}

type DispatchProps = {
  onSubmit: (values: AdvancedTargetingReduxFormProps) => void
  resetForm: () => void
}

type StateProps = {
  formNetworkVisibility: IAutocompleteSearchResult[]
  formLocationVisibility: ILocationSearchResult[]
  initialValues: IPharmacistListState
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onSubmit: (values: AdvancedTargetingReduxFormProps) => {
      const params = {
        locationInclude: values.locationInclude ?? [],
        locationExclude: values.locationExclude ?? [],
        networkInclude: values.networkInclude ?? [],
        networkExclude: values.networkExclude ?? [],
      }

      dispatch(setTargetingFilter(params))
    },
    resetForm: () => {
      dispatch(destroy('advancedTargetingForm'))
      dispatch(resetFilter())
    },
  }
}

export default connect<StateProps, DispatchProps, TargetingFilterFormProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedTargetingReduxForm)
