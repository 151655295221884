import { connect } from 'react-redux'

/* eslint-disable react/display-name */
import EmployeeCourseStatisticsTable, {
  EmployeeCourseStatisticsTableDispatchProps,
  EmployeeCourseStatisticsTableStateProps,
  FetchDataProps,
} from '../../../../../shared/components/EmployeeStatistics/Tables/EmployeeCourseStatisticsTable'
import { employeeCourseStatisticsFetchTable } from '../../../../reducers/employeeStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'

const mapStateToProps = (store: IAppState): EmployeeCourseStatisticsTableStateProps => {
  return {
    tableFilters: store.employeeStatistics.tableFilters,
    fetchedData: store.employeeStatistics.fetchedData,
    totalRowCount: store.employeeStatistics.totalRowCount,
    loading: store.employeeStatistics.loading,
    employeeId: store.pharmacistInfo.info.userId,
    detail: store.employeeStatistics.detail,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onFetchData: (props: FetchDataProps) => {
      dispatch(employeeCourseStatisticsFetchTable(props))
    },
  }
}

export default connect<
  EmployeeCourseStatisticsTableStateProps,
  EmployeeCourseStatisticsTableDispatchProps,
  unknown,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCourseStatisticsTable)
