import { Action, combineReducers } from 'redux'
import { FormStateMap, reducer as formReducer } from 'redux-form'
import { ThunkDispatch } from 'redux-thunk'

import { createAction } from '@reduxjs/toolkit'

import applicationReducer, { IApplicationState } from '../../shared/reducers/application/applicationReducer'
import menuItemsReducer, { IMenuItemsState } from '../../shared/reducers/menuItems/menuItemsReducer'
import personalProfileReducer, {
  IPersonalProfileState,
} from '../../shared/reducers/personalProfile/personalProfileReducer'
import provisorExperienceReducer, {
  IProvisorExperienceState,
} from '../../shared/reducers/provisorExperience/provisorExperienceReducer'
import employeeStatisticsReducer, { EmployeeStatisticFiltersState } from './employeeStatisticsReducer'
import pharmacistInfoReducer, { PharmacistInfoState } from './pharmacistInfoReducer'
import regionalManagerReducer, { IRegionalManagerState } from './regionalManagerReducer'
import alertReducer, { IAlertState } from 'shared/reducers/alert/alertReducer'
import pharmacistListReducer, { IPharmacistListState } from './pharmacistListReducer'
import pharmacistStatisticsReducer, { IPharmacistStatisticsState } from 'shared/reducers/pharmacistStatisticsInfo/pharmacistStatisticsInfoReducer'

export interface IAppState {
  dispatch: AppDispatch
  form: FormStateMap
  menuItems: IMenuItemsState
  regionalManager: IRegionalManagerState
  profile: IPersonalProfileState
  application: IApplicationState
  pharmacists: IPharmacistListState
  pharmacistInfo: PharmacistInfoState
  employeeStatistics: EmployeeStatisticFiltersState
  provisorExperience: IProvisorExperienceState
  alert: IAlertState,
  pharmacistStatistics: IPharmacistStatisticsState
}

export type AppDispatch = ThunkDispatch<IAppState, unknown, Action>

const appReducer = combineReducers({
  form: formReducer,
  menuItems: menuItemsReducer,
  regionalManager: regionalManagerReducer,
  profile: personalProfileReducer,
  application: applicationReducer,
  pharmacists: pharmacistListReducer,
  pharmacistInfo: pharmacistInfoReducer,
  employeeStatistics: employeeStatisticsReducer,
  provisorExperience: provisorExperienceReducer,
  alert: alertReducer,
  pharmacistStatistics: pharmacistStatisticsReducer
})

export const resetAppState = createAction('RESET_STATE')

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}
