import classNames from 'classnames'
import React, { ReactElement, useState } from 'react'

import { IExperienceDetail } from '../../../shared/proto/models'
import ArrowIcon from '../Icons/ArrowIcon'
import {
  provisors,
  provisorsBlock,
  provisorsBlockOpen,
  provisorsIcon,
  provisorsItem,
  provisorsList,
  provisorsText,
  provisorsValue,
} from './ProvisorExperience.module.scss'

interface IProvisorExperienceProps {
  total: number
  data: IExperienceDetail[]
}

const ProvisorExperience = (props: IProvisorExperienceProps): ReactElement<IProvisorExperienceProps> => {
  const [open, setOpen] = useState(false)

  const toggleSelect = () => {
    setOpen((prev) => !prev)
  }

  return (
    <div className={provisors}>
      <div onClick={toggleSelect} className={classNames(provisorsBlock, { [provisorsBlockOpen]: open })}>
        <span className={provisorsIcon}>
          <ArrowIcon position={open ? 'up' : 'down'} />
        </span>
        <span className={provisorsText}>Провизоров в системе:</span>
        <span className={provisorsValue}>{props.total}</span>
      </div>
      {open && (
        <ul className={provisorsList}>
          {props.data.map((item, index) => {
            return (
              <li className={provisorsItem} key={index}>
                <span className={provisorsText}>{item.description}:</span>
                <span className={provisorsValue}>{item.value}</span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default ProvisorExperience
