import React, { useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'
import PageInfoHeader from 'shared/components/PageInfoHeader/PageInfoHeader'
import PageTitle from 'shared/components/PageTitle'

import { IRegManagerOrganization } from '../../proto/models'
import { fetchOrganization } from '../../reducers/regionalManagerReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'

const getDataPageInfoHeader = (info: IRegManagerOrganization) => {
  return [
    {
      id: nextId(),
      title: 'Наименование компании',
      value: info?.name,
    },
  ]
}

type StateProps = {
  info: IRegManagerOrganization
}

type OwnProps = unknown

type DispatchProps = {
  fetch: () => void
}

type Props = StateProps & OwnProps & DispatchProps

const Header = (props: Props): React.ReactElement<Props> => {
  useEffect(() => {
    props.fetch()
  }, [])

  const dataPageInfoHeader = getDataPageInfoHeader(props.info)
  return (
    <>
      <PageTitle title="Провизоры" />
      <PageInfoHeader data={dataPageInfoHeader} />
    </>
  )
}

const mapStateToProps = (store: IAppState): StateProps => {
  return {
    info: store.regionalManager.info,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetch: () => {
      dispatch(fetchOrganization())
    },
  }
}

export default connect<StateProps, DispatchProps, OwnProps, IAppState>(mapStateToProps, mapDispatchToProps)(Header)
