import { createReducer, Dispatch } from '@reduxjs/toolkit'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { IExperienceDetail, IProvisorExperienceList, ProvisorExperienceList } from 'shared/proto/models'

export interface IProvisorExperienceState {
  total: number
  provisors: IExperienceDetail[]
  loading: boolean
}

const initialState: IProvisorExperienceState = {
  total: 0,
  provisors: [],
  loading: true,
}

const GET_PROVISOR_EXPERIENCE = 'GET_PROVISOR_EXPERIENCE'
export const getProvisorExperience = () => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IProvisorExperienceList>('provisor-experience', ProvisorExperienceList)

  dispatch({ type: GET_PROVISOR_EXPERIENCE, payload: response })
  dispatch({ type: SET_LOADING, payload: false })
}

const SET_LOADING = 'SET_LOADING'

const ProvisorExperienceReducer = createReducer(initialState, {
  [GET_PROVISOR_EXPERIENCE]: (state, action) => {
    state.provisors = action.payload.provisorsExperience
    state.total = action.payload.total
  },
  [SET_LOADING]: (state, action) => {
    state.loading = action.payload
  },
})

export default ProvisorExperienceReducer
