import BlockFilters, {
  BlockFiltersDispatchProps,
  BlockFiltersStateProps,
} from 'shared/components/EmployeeStatistics/Filter/BlockFilters'
import { connect } from 'react-redux'

import {
  changeEndDate,
  changeFilters,
  changeStartDate,
  setFilterOpen,
} from '../../../../reducers/employeeStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'

const mapStateToProps = (store: IAppState): BlockFiltersStateProps => {
  return {
    startDate: store.employeeStatistics.startDate,
    endDate: store.employeeStatistics.endDate,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): BlockFiltersDispatchProps => {
  return {
    changeStartDate: (date) => dispatch(changeStartDate(date)),
    changeEndDate: (date) => dispatch(changeEndDate(date)),
    changeFilters: () => dispatch(changeFilters()),
    setFilterOpen: () => dispatch(setFilterOpen()),
  }
}

export default connect<BlockFiltersStateProps, BlockFiltersDispatchProps, unknown, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(BlockFilters)
