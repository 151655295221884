import React, { useState } from 'react'
import { connect } from 'react-redux'

import { TargetingFilterFormProps } from '../../../shared/components/CourseDesigner/Publications/CreatePublication/CreatePublication'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import PharmacistsTable from './Table/PharmacistsTable'

type StateProps = {
  dispatch: AppDispatch
}

type OwnProps = {
  AdvancedFilterForm: (props: TargetingFilterFormProps) => React.ReactElement<TargetingFilterFormProps>
}

type Props = StateProps & OwnProps

const CreatePublication = (props: Props): React.ReactElement<Props> => {
  const [open, setOpen] = useState(false)

  return open ? (
    <props.AdvancedFilterForm
      closeForm={() => {
        setOpen(false)
      }}
      dispatch={props.dispatch}
      onSubmitSuccess={() => setOpen(false)}
    />
  ) : (
    <PharmacistsTable
      handleAdvancedFilterOpen={() => {
        setOpen(!open)
      }}
    />
  )
}

const mapStateToProps = (store: IAppState): StateProps => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect<StateProps, unknown, OwnProps, IAppState>(mapStateToProps)(CreatePublication)
